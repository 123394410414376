@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Play:wght@400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Genos&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Orbitron:wght@400;800&display=swap');

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  --clr-grey: #323946;
  --clr-white: #FFFFFF;
  --clr-blue: #009ed8;
  --clr-blue-2: #0061d8;
  --clr-grey2: #1f232d;
}

body {
  background: var(--clr-grey);
  margin: 0;
  font-family: "Genos";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* -- Description Text Dp -- */


.logo {
  width: 250px; 
  height: 150px; 
  display: block;
}


.home {
  margin-top: 16rem;
  padding: 6rem 0;
  display: flex;
  align-items: left;
  text-align: left;
  margin: 0 auto;
  margin-right: auto;
  margin-left: auto;
  margin-bottom: -5rem;
}

.home-content {
  margin: 0;
  max-width: 800px;
  padding: 0 2rem;
  margin-right: 2rem;
  margin-left: 20rem;
}

.home-icon img {
  width: 420px;
  height: 442px;
  animation: floatImage 4s ease-in-out infinite;
  max-width: 100%;
  height: auto;
  margin-right: 23rem;
  margin-left: -6rem;
}

@keyframes floatImage {
  0% {
      transform: translateY(0.5rem);
  }
  50% {
      transform: translateY(-0.5rem);
  }
  100% {
      transform: translateY(0.5rem);
  }
}

.home h1 {
  color: var(--clr-blue);
  font-family: "Orbitron";
  margin-bottom: 2rem;
  animation: neon2 1.5s ease-in-out infinite alternate;
}

@keyframes neon2 {
  from {
    text-shadow: 0 0 0px #fff;
  }
  to {
    text-shadow: 0 0 5px #fff;
  }
}

.home h4 {
  color: var(--clr-white);
}

.home h3 {
  color: var(--clr-white);
  margin-bottom: 2rem;
}


/* ------------------------------------------------------ */
/* -- NAVIGATION -- */




nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  background-color: var(--clr-grey2);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  position: relative;
}

.container {
  max-width: 100%;
  padding-right: 1rem;
  padding-left: 1rem;
  margin-right: auto;
  margin-left: auto;
  margin: 0 auto;
  padding: 0 2rem;
  height: 13rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.nav__brand {
  display: flex;
  align-items: center;
  margin-right: auto;
  margin-left: 18rem;
}

.nav__brand img {
  width: 100%;
  max-width: 420px;
  height: auto;
  margin-right: auto;
}

.nav__brand h1 {
  color: var(--clr-blue);
  font-size: clamp(1.75em, 4vw, 2.5em);
  font-weight: 800;
}

.nav__connect {
  width: 175px;
  height: 50px;
  margin: 0;
  margin-left: 10rem;
  margin-right: auto;

  background-color: var(--clr-blue);
  color: var(--clr-white);

  border: none;
  border-radius: 4px;

  font-family: "Genos";
  font-size: 1.20em;
  font-weight: 600;

  cursor: pointer;
  transition: all 250ms ease;
}

.nav__connect:hover {
  background-color: var(--clr-blue-2);
}

/* ------------------------------------------------------ */
/* -- FORM -- */

.spintext {
  color: #FFFFFF;
}

.spinner {
  color: #FFFFFF;
}

.form {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: -4rem;
  min-height: 600px;
}

.gifer {
  width: 500px; 
  height: 357.14px;
  margin-bottom: 16.6rem;
  margin-right: -17rem;
  margin-left: -6rem;
  display: flex;
  justify-content: center;
  align-items: left;
}

form {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;

  margin: 0 25px
}

form input[type="text"] {
  width: 300px;
  height: 60px;
  margin: 10px 0;
  padding: 10px;

  border: 1px solid var(--clr-blue);
  border-radius: 4px;
  font-size: 1.30em;
  text-shadow: 0 0 3px #fff;
  text-shadow: 0 0 -3px #fff;
}

form input[type="submit"] {
  background-color: var(--clr-blue);
  color: var(--clr-white);

  width: 300px;
  height: 60px;
  margin: 10px 0;
  padding: 10px;

  border: none;
  border-radius: 4px;

  font-family: "Genos";
  font-size: 1.30em;
  font-weight: 400;

  cursor: pointer;
  transition: all 250ms ease;
}

form input[type="submit"]:hover {
  background-color: var(--clr-blue-2);
}

/* ------------------------------------------------------ */
/* -- IMAGE -- */

.image {
  display: flex;
  justify-content: center;
  align-items: center;

  max-width: 90%;
  width: 512px;
  height: 512px;
  margin: 0 25px;
  border: 3px solid var(--clr-blue);
  border-radius: 4px;

  overflow: hidden;
}

.image img {
  width: 512px;
  height: 512px;
  border-radius: 6px;
}

.image__placeholder {
  display: flex;
  justify-content: center;
  align-items: center;
}

.image__placeholder p {
  margin: 0 10px;
}

.Metadata {
  margin-top: 4rem;
}

.Thankyou h3 {
  color: var(--clr-white);
  text-align: center;
}


/* ------------------------------------------------------ */
/* -- TEXT -- */
p {
  display: flex;
  justify-content: center;
  align-items: center;
}


/* -- TABLET OPTIMISATION -- */

@media (max-width: 1900px) {

  .home-icon img {
    margin-right: 25rem;
  }

  .home-content {
    margin-left: 24rem;
  }
}


@media (max-width: 1810px) {

  .nav__brand {
    margin-left: 16rem;
  }

  .nav__connect {
    margin-left: 2rem;
  }

  .home-icon img {
    margin-right: 26rem;
  }

  .home-content {
    margin-left: 18rem;
  }
}

@media (max-width: 1610px) {

  .nav__brand {
    margin-left: 14rem;
  }

  .home-icon img {
    margin-right: 17rem;
  }

  .home-content {
    margin-left: 12rem;
  }

  .nav__connect {
    margin-left: 0rem;
  }
}


@media (max-width: 1510px) {

  .nav__brand {
    margin-left: 14rem;
  }

  .home-icon img {
    margin-right: 20rem;
  }

  .home-content {
    margin-left: 12rem;
  }

  .nav__connect {
    margin-left: 0rem;
  }
}

@media (max-width: 1310px) {

  .nav__brand {
    margin-left: 10rem;
  }

  .home-icon img {
    margin-right: 16rem;
  }

  .home-content {
    margin-left: 10rem;
  }

  .nav__connect {
    margin-left: 4rem;
  }
}


@media (max-width: 1240px) {
  html {
    font-size: 85%;
  }

  .home-icon img {
    width: 315px;
    height: 331px;
    margin-right: 32rem;
  }

  .nav__brand img {
    max-width: 360px;
    height: auto;
  }

  .nav__connect {
    font-size: 1.40em;
    font-weight: 600;
    width: 155px;
    height: 45px;
    margin-left: 3rem;
  }

  .nav__brand {
    margin-left: 11rem;
  }

  .home-icon img {
    margin-right: 16rem;
  }

  .home-content {
    margin-left: 12rem;
  }

  
}

@media (max-width: 1150px) {

  .home-icon img {
    margin-right: 18rem;
  }

  .home-content {
    margin-left: 10rem;
  }

}

@media (max-width: 1080px) {

  .nav__brand {
    margin-left: 8rem;
  }

  .home-icon img {
    margin-right: 16rem;
  }
  
  .home {
    margin-bottom: 4rem;
  }

  .home-content {
    margin-left: 9rem;
  }

  .gifer {
    display: none;
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    flex-direction: column-reverse;
    margin-left: 1rem;
  }

  .form {
    gap: 5rem;
  }

  
  .nav__connect {
    margin-left: 0.5rem;
  }

}

@media (max-width: 1000px) {


  .nav__brand img {
    max-width: 310px;
    height: auto;
  }

  .nav__connect {
    font-size: 1.30em;
    font-weight: 600;
    width: 135px;
    height: 40px;
    margin-left: 2rem;
  }


}



@media (max-width: 991px) {
  .header {
      padding: 2rem 3%;
  }

  section {
      padding: 10rem 3% 2rem;
  }

  .home-content {
    margin-left: 6rem;
  }

  .home {
    flex-direction: column;
    align-items: center;
    padding: 6rem 0;
    justify-content: center;
    width: 100%;
    height: auto;
    margin-left: -2.5rem;
    margin-top: 0rem;
    margin-bottom: 1rem;
  }

  .home-icon img {
    margin-left: 22rem;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }
}

@media (max-width: 920px) {

  .home-icon img {
    margin-left: auto;
    margin-right: 7rem;
  }

  .nav__brand {
    margin-left: 4rem;
  }

  .home-content {
    margin-left: -8rem;
    padding: 0rem auto;
    max-width: auto;

  }

  .home {
    width: 120%;
  }
}

@media (max-width: 720px) {

  .nav__brand {
  margin-left: 3.5rem;
}

  .home-content {
  margin-left: -2rem;
}

  .home-icon img {
  margin-right: 6rem;
}

}

@media (max-width: 640px) {

  .nav__brand {
    margin-left: 2rem;
  }

  .home-icon img {
    margin-right: 5rem;
  }
}

@media (max-width: 600px) {

  .nav__brand {
    margin-left: 1rem;
  }

  .nav__connect {
    margin-left: 0.5rem;
  }

  .home-content {
    margin-left: 0rem;
  }
}

/* -- Responsive form and square -- */


@media (max-width: 580px) {    

  .image {
    width: 100%;
    height: auto;
    padding-top: 100%; /* Set the height to match the width */
    position: relative;
  }

  .image img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover; /* Crop the image to fit the container */
  }

  .form {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 200px;
    flex-direction: column-reverse;
  }

  form input[type="text"] {
    font-size: 140%;
  }

  form input[type="submit"] {
    font-size: 140%;
  }

  .spintext {
    position: absolute;
    top: 48.5%;
    left: 39%;
  }

  .spinner {
    position: absolute;
    top: 48%;
    left: 30%;
  }

}

@media (max-width: 551px) {

  html {
    font-size: 75%;
  }

  .nav__brand {
    margin-left: 1.5rem;
  }

  .nav__connect {
    width: 115px;
    height: 35px;
    font-size: 1.2rem;
  }

  .home-content {
    margin-left: -1.5rem;
  }

  .home-icon img {
    margin-right: 6rem;
    width: 240px;
    height: 262px;
  }

  .nav__brand img {
    margin-top: 0.5rem;
    max-width: 230px;
    height: auto;
  }

  .container {
    height: 10rem;
  }


}

@media (max-width: 470px) {

  .nav__brand {
    margin-left: 0rem;
  }

  .home-content {
      font-size: 0.9rem;
      padding: 0 4rem;
      margin-left: 0rem;
      margin-right: 2rem;
  }

  .home-icon img {
    margin-right: 4rem;
    width: 210px;
    height: 230px;
    margin-bottom: 0.5rem;
  }
}

@media (max-width: 460px) {
  .home-content {
    margin-left: 0.5rem;
  }
}

@media (max-width: 400px) {
  .home-content {
    margin-left: 2rem;
  }

  .container {
    flex-direction: column;
    align-items: center;
    height: 14rem;
  }

  .nav__brand {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    gap: 0rem;
  }

  .nav__connect {
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    width: 90px;
    height: 28px;
    font-size: 1.1rem;
    margin-bottom: 2rem;
  }

  .home-icon img {
    margin-right: 3rem;
  }
}

@media (max-width: 330px) {

  .home-icon img {
    margin-right: 2rem;
  }

  .home-content {
    font-size: 0.9rem;
    padding: 0 3rem;
    margin-left: 4rem;
    margin-right: 4rem;
  }

}

@media (max-width: 270px) {
  .home-icon img {
    margin-right: 1rem;
  }
}